export default [
  {
    title: 'My reservations',
    route: 'home',
    icon: 'CalendarIcon',
  },
  {
    title: 'My account',
    route: 'account',
    icon: 'UserIcon',
  },
  {
    title: 'Reservation preferences',
    route: 'preferences',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'My reviews',
    route: 'reviews',
    icon: 'StarIcon',
  },
]
